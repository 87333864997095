'use client'

import mixpanelLib, { type Callback, type Mixpanel } from 'mixpanel-browser'

import { mixpanelEventName } from 'utils/tracking/mixpanel/mixpanelConstants'
import { getSessionStore } from 'store/user'
import { getUAFromCookie } from 'utils/helpers/getUAFromCookie'

const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || ``
const MIXPANEL_DEBUG = process.env.NEXT_PUBLIC_MIXPANEL_DEBUG === 'true'
const MIXPANEL_TOGGLE = process.env.NEXT_PUBLIC_ENABLE_MIXPANEL === 'true'

let instance: Mixpanel | null

export const getMixpanelInstance = () => instance

const withMixpanelInstanceChecking = (fn: (mixpanel: Mixpanel) => void) => {
  if (!instance) {
    return
  }

  fn(instance)
}

/**
 * Initializes Mixpanel if enabled.
 * @param callback Optional callback function to execute after Mixpanel is loaded.
 */
export const mixpanelInit = (callback?: (mp: Mixpanel) => void) => {
  if (!MIXPANEL_TOGGLE || instance) {
    return
  }

  mixpanelLib.init(`${MIXPANEL_TOKEN}`, {
    debug: MIXPANEL_DEBUG,
    persistence: 'localStorage',
    ignore_dnt: true,
    loaded: (mixpanel) => {
      instance = mixpanel

      if (callback) callback(mixpanel)
    },
  })
}

/**
 * Tracks a page view event.
 * @param properties Additional properties to include with the page view event.
 */
export const trackPageView: typeof mixpanelLib.track_pageview = (properties) =>
  withMixpanelInstanceChecking((mp) => {
    const { isLoggedIn, user, customerAddress } = getSessionStore().getState()

    mp.track(mixpanelEventName.OPEN_SCREEN, {
      isLoggedInStatus: isLoggedIn,
      userId: user?.id,
      locationId: customerAddress?.location_id,
      ...properties,
    })
  })

export type TrackEventsPropertiesType = Partial<{
  /**
   * Category of the event being tracked.
   */
  eventCategory: string
  /**
   * Action associated with the event being tracked.
   */
  eventAction: string
  /**
   * Label associated with the event being tracked.
   */
  eventLabel: string
  /**
   * Additional properties that can be passed to custom tracking events.
   * These properties can have string or object values.
   */
  [key: string]: string | object | unknown
}>

/**
 * Tracks custom events.
 * @param event Name of the event to track.
 * @param properties Additional properties to include with the event.
 * @param callback Optional callback function to execute after tracking the event.
 */
export const trackEvents = (
  event: string,
  properties?: TrackEventsPropertiesType,
  callback?: Callback,
) => {
  withMixpanelInstanceChecking((mp) => {
    const { isLoggedIn, user, customerAddress } = getSessionStore().getState()

    const { device, os } = getUAFromCookie()
    const vp = device?.type === 'mobile' ? 'mobile' : 'desktop'
    const parsedOS = os?.name?.toLocaleLowerCase()

    const mergedProps: TrackEventsPropertiesType = {
      isLoggedInStatus: isLoggedIn,
      userId: user?.id,
      locationId: `${customerAddress?.location_id}`,
      deviceType: `web ${vp}`,
      deviceOs: parsedOS,
      ...properties,
    }

    // Remove undefined keys
    Object.keys(mergedProps).forEach(
      (key) => mergedProps[key] === undefined && delete mergedProps[key],
    )

    mp.track(event, mergedProps, callback)
  })
}

export const getScreenNameMetaFromDocument = () => {
  if (window === undefined) {
    throw new Error('getScreenNameMetaFromDocument method only run on client side')
  }

  const screenNameMeta = document.querySelector('meta[name="screenName"]')

  return screenNameMeta?.getAttribute('content') || 'untitled'
}
